<template>
  <div :class="$style.forbidden">
    <!-- <div :class="$style.code">403</div> -->
    <div :class="$style.tipText">没有访问权限</div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { ErrorPage } from '@triascloud/x-blocks';

@Component({
  components: { ErrorPage },
})
export default class ForbiddenPage extends Vue {}
</script>
<style lang="less" module>
.forbidden {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--screen-font-1);
  font-family: YouSheBiaoTiHei;
  line-height: 0.65rem;
  .code {
    font-size: 1rem;
  }
  .tipText {
    font-size: 0.45rem;
  }
}
</style>
